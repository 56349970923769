import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledOurMission = styled.section`
  padding: 60px 0 10px;
  background-color: ${colors.white};
  color: ${colors.charcoal};
  text-align: center;

  ${breakpoint.medium`
    padding: 100px 0 20px;
  `}

  h2 {
    color: ${colors.charcoal};
    padding-bottom: 2rem;    
  }

  p {
    margin: 0 auto 32px auto;

    ${breakpoint.medium`
      margin-bottom: 50px;
    `}
  }

`

const OurMission = () => {
  return (
    <StyledOurMission data-aos="fade-up">
      <Container>
        <h2>Our mission</h2>
        <p> Empower anyone to test for any infection anywhere with near-100% accuracy.</p>
      </Container>
    </StyledOurMission>
  )
}
export default OurMission